$(document).ready(function () {
	//Hide element when smaller than 1025
	if ($(window).width() < 1025) {
		$(".header-bottom").fadeIn(function () {
			$(".header-bottom").css({
				display: "flex",
			});
		});
	}
	setBackground();
	tabActive();
	swiperInit();
	toggleFunc();
});

Fancybox.bind("[data-fancybox]", {
	parentEl: document.forms[0], // Element containing main structure
});

function swiperInit() {
	var primarySwiper = new Swiper(".primary-banner .swiper", {
		// Optional parameters
		slidesPerView: 1,
		speed: 1205,
		autoplay: {
			delay: 5000,
			pauseOnMouseEnter: true,
		},
		loop: true,
		effect: "fade",
		fadeEffect: {
			crossFade: true,
		},
		lazy: {
			loadPrevNext: true,
		},
		navigation: {
			nextEl: ".primary-banner .nav-arrow-next",
			prevEl: ".primary-banner .nav-arrow-prev",
		},
		pagination: {
			el: ".primary-banner  .swiper-pagination",
			type: "bullets",
			clickable: true,
		},
	});

	var topBanner = new Swiper(".top-banner .swiper", {
		// Optional parameters
		speed: 1205,
		lazy: {
			loadPrevNext: true,
		},
		slidesPerView: 1,
		autoplay: {
			delay: 3000,
			pauseOnMouseEnter: true,
		},
	});

	var recruitmentSwiper = new Swiper(".recruitment-list  .swiper", {
		// Optional parameters
		speed: 1205,
		spaceBetween: 30,
		lazy: {
			loadPrevNext: true,
		},
		autoplay: {
			delay: 3000,
			pauseOnMouseEnter: true,
		},
		breakpoints: {
			420: {
				slidesPerView: 2,
			},
			450: {
				slidesPerView: 2,
			},
			1024: {
				spaceBetween: 10,
				slidesPerView: 3,
				grid: {
					rows: 2,
				  },
			},
			1280: {
				slidesPerView: 3,
				spaceBetween: 30,
				grid: {
					rows: 2,
				  },
			},
		},
		navigation: {
			nextEl: ".recruitment-list .nav-next",
			prevEl: ".recruitment-list  .nav-prev",
		},

	});

	// 3 swiper sync
	var swiperSyncText = new Swiper(".swiper-sync-text", {
		// Optional parameters
		speed: 300,
		spaceBetween: 30,
		autoplay:false,
		pagination: {
			el: ".swiper-sync .swiper-pagination",
			type: "bullets",
			clickable: true,
		},
		lazy: {
			loadPrevNext: true,
		},
		slidesPerView: 1,
	});
	var swiperSyncBtn = new Swiper(".swiper-sync-btn", {
		// Optional parameters
		speed: 300,
		autoplay:false,
		lazy: {
			loadPrevNext: true,
		},
		pagination: {
			el: ".swiper-sync .swiper-pagination",
			type: "bullets",
			clickable: true,
		},
		slidesPerView: 1,
	});
	var swiperSyncImg = new Swiper(".swiper-sync-img", {
		// Optional parameters
		speed: 300,
		autoplay:false,
		lazy: {
			loadPrevNext: true,
		},
		pagination: {
			el: ".swiper-sync .swiper-pagination",
			type: "bullets",
			clickable: true,
		},
		slidesPerView: 1,
	});
	swiperSyncText.on("slideChange", function () {
		swiperSyncBtn.slideTo(swiperSyncText.activeIndex);
		swiperSyncImg.slideTo(swiperSyncText.activeIndex);
	});
	swiperSyncBtn.on("slideChange", function () {
		swiperSyncText.slideTo(swiperSyncBtn.activeIndex);
		swiperSyncImg.slideTo(swiperSyncBtn.activeIndex);
	});
	swiperSyncImg.on("slideChange", function () {
		swiperSyncBtn.slideTo(swiperSyncImg.activeIndex);
		swiperSyncText.slideTo(swiperSyncImg.activeIndex);
	});

	var swiperSyncText2 = new Swiper(".swiper-sync-text-2", {
		// Optional parameters
		speed: 300,
		spaceBetween: 30,
		autoplay: {
			delay: 5000,
		},
		pagination: {
			el: ".swiper-sync-2 .swiper-pagination",
			type: "bullets",
			clickable: true,
		},
		lazy: {
			loadPrevNext: true,
		},
		slidesPerView: 1,
	});
	var swiperSyncImg2 = new Swiper(".swiper-sync-img-2", {
		// Optional parameters
		speed: 300,
		autoplay: {
			delay: 5000,
		},
		lazy: {
			loadPrevNext: true,
		},
		pagination: {
			el: ".swiper-sync-2 .swiper-pagination",
			type: "bullets",
			clickable: true,
		},
		slidesPerView: 1,
	});
	swiperSyncText2.on("slideChange", function () {
		swiperSyncImg2.slideTo(swiperSyncText2.activeIndex);
	});
	swiperSyncImg2.on("slideChange", function () {
		swiperSyncText2.slideTo(swiperSyncImg2.activeIndex);
	});

	var swiperSyncText3 = new Swiper(".swiper-sync-text-3", {
		// Optional parameters
		speed: 300,
		spaceBetween: 30,
		autoplay: {
			delay: 5000,
		},
		pagination: {
			el: ".swiper-sync-3 .swiper-pagination",
			type: "bullets",
			clickable: true,
		},
		lazy: {
			loadPrevNext: true,
		},
		slidesPerView: 1,
	});
	var swiperSyncImg3 = new Swiper(".swiper-sync-img-3", {
		// Optional parameters
		speed: 300,
		autoplay: {
			delay: 5000,
		},
		lazy: {
			loadPrevNext: true,
		},
		pagination: {
			el: ".swiper-sync-3 .swiper-pagination",
			type: "bullets",
			clickable: true,
		},
		slidesPerView: 1,
	});
	swiperSyncText3.on("slideChange", function () {
		swiperSyncImg3.slideTo(swiperSyncText3.activeIndex);
	});
	swiperSyncImg3.on("slideChange", function () {
		swiperSyncText3.slideTo(swiperSyncImg3.activeIndex);
	});

	var productThumb = new Swiper(".swiper-media-thumb .swiper", {
		spaceBetween: 10,
		breakpoints: {
			200: {
				slidesPerView: 2,
				direction: "horizontal",
			},
			576: {
				slidesPerView: 2,
				direction: "horizontal",
			},

			1024: {
				spaceBetween: 10,
				slidesPerView: 3,
				direction: "vertical",
			},
			1280: {
				spaceBetween: 10,
				slidesPerView: 4.2,
				direction: "vertical",
			},
		},
		pagination: {
			el: ".swiper-media-thumb .swiper-pagination",
			type: "progressbar",
		},

		watchSlidesVisibility: true,
		watchSlidesProgress: true,
	});
	var productMain = new Swiper(".swiper-media-main .swiper", {
		spaceBetween: 10,
		speed: 1205,
		autoplay: {
			delay: 3000,
		},
		lazy: {
			loadPrevNext: true,
		},
		thumbs: {
			swiper: productThumb,
		},
		navigation: {
			nextEl: ".swiper-media-main .nav-arrow-next",
			prevEl: ".swiper-media-main  .nav-arrow-prev",
		},
	});
	var VideoThumb = new Swiper(".swiper-video-thumb .swiper", {
		spaceBetween: 23,
		breakpoints: {
			200: {
				slidesPerView: 2,
			},
			576: {
				slidesPerView: 2,
			},
			1024: {
				slidesPerView: 3,
			},
			1280: {
				slidesPerView: 4,
			},
		},
		pagination: {
			el: ".swiper-video-thumb .swiper-pagination",
			type: "progressbar",
		},
		watchSlidesVisibility: true,
		watchSlidesProgress: true,
	});
	var VideoMain = new Swiper(".swiper-video-main .swiper", {
		spaceBetween: 10,
		speed: 1205,
		autoplay: {
			delay: 3000,
		},
		lazy: {
			loadPrevNext: true,
		},

		navigation: {
			nextEl: ".swiper-video-thumb .nav-arrow-next",
			prevEl: ".swiper-video-thumb  .nav-arrow-prev",
		},
	});
	var homeCategoryZone = new Swiper(".home-zone-swiper  .swiper", {
		// Optional parameters
		speed: 1205,
		spaceBetween: 30,
		lazy: {
			loadPrevNext: true,
		},
		breakpoints: {
			200: {
				slidesPerView: 2,
			},
			600: {
				slidesPerView: 3,
			},
			1024: {
				slidesPerView: 5,
			},
			1366.1: {
				slidesPerView: 6,
			},
			1440.1: {
				slidesPerView: 7,
			},
		},
		autoplay: {
			delay: 3000,
			pauseOnMouseEnter: true,
		},
		navigation: {
			nextEl: ".home-zone-swiper .nav-arrow-next",
			prevEl: ".home-zone-swiper  .nav-arrow-prev",
		},
	});

	var homeCategoryZone = new Swiper(".home-room-swiper  .swiper", {
		// Optional parameters
		speed: 1205,
		spaceBetween: 30,
		lazy: {
			loadPrevNext: true,
		},
		slideToClickedSlide: true,
		breakpoints: {
			300: {
				slidesPerView: 1,
			},
			500: {
				slidesPerView: 2,
			},
			960: {
				slidesPerView: 3,
				centeredSlides: true,
			},
			1280: {
				slidesPerView: 4,
				spaceBetween: 30,
				centeredSlides: true,
			},
			1600.1: {
				slidesPerView: 5,
				spaceBetween: 85,
				centeredSlides: true,
			},
		},
		pagination: {
			el: ".home-room-swiper  .swiper-pagination",
			type: "fraction",
		},
		autoplay: {
			delay: 3000,
			pauseOnMouseEnter: true,
		},
		navigation: {
			nextEl: ".home-room-swiper .nav-next",
			prevEl: ".home-room-swiper  .nav-prev",
		},
	});

	try {
		if ($(window).width() > 1025) {
			homeCategoryZone.slideTo(2);
			homeCategoryZone.autoplay.start();
		}
	} catch (error) {}

	$(".single-swiper .swiper").each(function (index, element) {
		var $this = $(this);
		$this.addClass("single-instance-" + index);
		$this
			.parent()
			.find(".nav-arrow-prev")
			.addClass("btn-prev-single-" + index);
		$this
			.parent()
			.find(".nav-arrow-next")
			.addClass("btn-next-single-" + index);
		$this
			.parent()
			.find(".swiper-pagination")
			.addClass("pagination-instance-" + index);

		var swiper = new Swiper(".single-instance-" + index, {
			speed: 1205,
			autoplay: {
				delay: 3000,
				pauseOnMouseEnter: true,
			},
			lazy: {
				loadPrevNext: true,
			},
			speed: 750,
			observer: true,
			spaceBetween: 30,
			observeParents: true,
			slidesPerView: 1,
			navigation: {
				nextEl: ".btn-next-single-" + index,
				prevEl: ".btn-prev-single-" + index,
			},
			pagination: {
				el: ".pagination-instance-" + index,
				type: "bullets",
				clickable: true,
			},
		});
	});
	$(".trinity-swiper .swiper").each(function (index, element) {
		var $this = $(this);
		$this.addClass("tri-instance-" + index);
		$this
			.parent()
			.find(".nav-arrow-prev")
			.addClass("btn-prev-tri-" + index);
		$this
			.parent()
			.find(".nav-arrow-next")
			.addClass("btn-next-tri-" + index);

		var swiper = new Swiper(".tri-instance-" + index, {
			speed: 1205,
			autoplay: {
				delay: 3000,
				pauseOnMouseEnter: true,
			},
			lazy: {
				loadPrevNext: true,
			},
			speed: 750,
			observer: true,
			spaceBetween: 30,
			observeParents: true,

			breakpoints: {
				200: {
					slidesPerView: 1,
				},
				576: {
					slidesPerView: 2,
				},
				769: {
					slidesPerView: 3,
				},
				1200: {
					slidesPerView: 3,
				},
			},
			navigation: {
				nextEl: ".btn-next-tri-" + index,
				prevEl: ".btn-prev-tri-" + index,
			},
		});
	});

	$(".four-swiper .swiper").each(function (index, element) {
		var $this = $(this);
		$this.addClass("instance-four" + index);
		$this
			.parent()
			.find(".nav-arrow-prev")
			.addClass("btn-prev-" + index);
		$this
			.parent()
			.find(".nav-arrow-next")
			.addClass("btn-next-" + index);

		var swiper = new Swiper(".instance-four" + index, {
			speed: 1205,
			autoplay: {
				delay: 3000,
				pauseOnMouseEnter: true,
			},
			lazy: {
				loadPrevNext: true,
			},
			speed: 750,
			observer: true,
			spaceBetween: 30,
			observeParents: true,

			breakpoints: {
				200: {
					slidesPerView: 1,
				},
				576: {
					slidesPerView: 2,
				},
				769: {
					slidesPerView: 3,
				},
				1280: {
					slidesPerView: 4,
				},
			},
			navigation: {
				nextEl: ".btn-next-" + index,
				prevEl: ".btn-prev-" + index,
			},
		});
	});
	$(".double-swiper .swiper").each(function (index, element) {
		var $this = $(this);
		$this.addClass("instance-quad" + index);
		$this
			.parent()
			.find(".nav-arrow-prev")
			.addClass("btn-prev-" + index);
		$this
			.parent()
			.find(".nav-arrow-next")
			.addClass("btn-next-" + index);

		var swiper = new Swiper(".instance-quad" + index, {
			speed: 1205,
			autoplay: {
				delay: 3000,
				pauseOnMouseEnter: true,
			},
			lazy: {
				loadPrevNext: true,
			},
			speed: 750,
			observer: true,
			spaceBetween: 30,
			observeParents: true,

			breakpoints: {
				200: {
					slidesPerView: 1,
				},
				576: {
					slidesPerView: 2,
				},
			},
			navigation: {
				nextEl: ".btn-next-" + index,
				prevEl: ".btn-prev-" + index,
			},
		});
	});
}

//Tab active

function tabActive() {
	$(".tab-navigation li a").on("click", function () {
		$(this).parents(".tab-navigation").find("li").removeClass("active");
		$(this).parents("li").addClass("active");

		var display = $(this).attr("data-type");
		$(this).parents("section").find(".tab-item").removeClass("active");
		$("#" + display).addClass("active");
	});
}
// Side
function setBackground() {
	$("[setBackground]").each(function () {
		var background = $(this).attr("setBackground");
		$(this).css({
			"background-image": "url(" + background + ")",
			"background-size": "cover",
			"background-position": "center center",
		});
	});
	$("[setBackgroundRepeat]").each(function () {
		var background = $(this).attr("setBackgroundRepeat");
		$(this).css({
			"background-image": "url(" + background + ")",
			"background-repeat": "repeat",
		});
	});
}

function toggleFunc() {
	var $hamburger = $(".hamburger");
	$(".main-menu-toggle").on("click", function () {
		$(".navbar-primary-menu ").toggleClass("open-menu");
		$hamburger.toggleClass("is-active");
	});
	$(".search-toggle").on("click", function () {
		$(".searchbox").toggleClass("open");
		$(".search-toggle").toggleClass("icon-change");
		if ($(".header-top-banner").hasClass("hidden")) {
			$(".searchbox.open").css({
				top: "55px",
			});
		}
		event.preventDefault();
		$('.searchbox  > input[type="text"]').focus();
	});

	$(".searchbox, .searchbox .close").on("click keyup", function (event) {
		if (
			event.target == this ||
			event.target.className == "close" ||
			event.keyCode == 27
		) {
			$(this).removeClass("open");
			$(".search-toggle").removeClass("icon-change");
		}
	});
	//mobile Nav
	$(".drop-down .title em").on("click", function () {
		if ($(this).parents(".drop-down").hasClass("open-sub")) {
			$(".drop-down .nav-sub").slideUp();
			$(".drop-down").removeClass("open-sub");
		} else {
			$(".drop-down .nav-sub").slideUp();
			$(".drop-down").removeClass("open-sub");
			$(this).parent().next().slideDown();
			$(this).parents(".drop-down").addClass("open-sub");
		}
	});
}
